import React from "react";
import Insight from "../../templates/content-types/insight";
import insightsData from "../../page-data/insights/riding-the-api-wave-data";
import { useRidingApi } from "../../utils/custom-hooks/image-hooks";

const Insights = ({location}) => {
  return (
    <Insight
      {...insightsData}
      location={location}
      useImage={useRidingApi}
    />
  );
};

export default Insights;
